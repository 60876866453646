<template>
  <div>
    <div class="filters row" style="margin-top:0">
      <div class="col-1-1">
        <div class="pull-right">
          <button v-on:click="openCreate" class="button button-success button-block">
            <fa-icon icon="plus" />
            Criar
          </button>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>#</th>
            <th>Descrição</th>
            <th>Chave</th>
            <th>Expediente</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items">
            <td class="highlight">
              {{ item.id }}
            </td>
            <td>{{ item.desc }}</td>
            <td>
              <span class="label primary">{{ item.chave }}</span>
            </td>
            <td>
              <span class="label warning">{{ retornaNomeExpediente(item.expediente_id) }}</span>
            </td>
            <td class="actions">
              <button type="button" class="button button-primary" v-on:click="openEdit(item)"><fa-icon icon="pencil-alt" /></button>
              <button type="button" name="button" v-on:click="deleteConfirm(item.id)" class="button button-error"><fa-icon icon="trash-alt" /></button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'list',
    props: [ 'items' , 'openEdit', 'openCreate', 'deleteConfirm', 'expedientes'],
    data() {
      return {}
    },
    computed: {},
    mounted() {},
    methods: {
      retornaNomeExpediente(expedienteId) {
          if(!expedienteId || expedienteId == null){
            return '*';
          }
          var resultado = this.expedientes.find(expediente => expediente.id == expedienteId);
          if(!resultado){
            return '*';
          }
          return resultado.descricao;
      }
    }
  }
</script>
