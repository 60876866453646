<template>
  <div>
    <modal name="setoresEdit" height="auto" width="900" :scrollable="true" :clickToClose="false">
      <CloseModal :close="close"/>
      <div class="modalbox">
        <div class="modalbox-content">
          <CTabs variant="tabs" class="nav-tabs-boxed">
            <CTab :title="`Salvando ${descSingular}`">
              <div class="xrow">
                <div class="col-1-2">
                  <div class="form-group">
                    <label for="">Descrição</label>
                    <input class="form-control" v-model="item.desc"/>
                    <div class="message">{{ validation.firstError('item.desc') }}</div>
                  </div>
                </div>
                <div class="col-1-2">
                  <div class="form-group">
                    <label for="">Chave</label>
                    <input class="form-control" v-model="item.chave"/>
                    <div class="message">{{ validation.firstError('item.chave') }}</div>
                  </div>
                </div>
                <div class="col-1-2">
                  <div class="form-group">
                    <label for="">Expediente</label>
                    <select class="form-control" v-model="item.expediente_id">
                      <option v-for="expediente in expedientes" v-bind:key="expediente.id" :value="expediente.id">
                        {{ expediente.descricao }}
                      </option>
                    </select>
                    <div class="message">{{ validation.firstError('item.expediente_id') }}</div>
                  </div>
                </div>
                <div class="col-1-2">
                  <div class="form-group">
                    <label for="">Centro de Custo</label>
                    <input class="form-control" v-model="item.setorerp_id"/>
                  </div>
                </div>
              </div>
              <div class="xrow">
                <div class="col-1-2">
                  <div class="form-group">
                    <label for="">Email</label>
                    <input class="form-control" v-model="item.email"/>
                  </div>
                </div>
              </div>
              <div class="xrow edit-buttons">
                <button :class="{ 'button button-success': true, 'button-disabled': validation.hasError() }"
                        v-on:click="handleSubmit">
                  Salvar
                </button>
                &nbsp;
                <button class="button" v-on:click="close">Fechar</button>
              </div>

            </CTab>
          </CTabs>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import {Validator} from 'simple-vue-validator';
import CloseModal from '../../../components/CloseModal';

export default {
  name: 'setoresEdit',
  props: ['item', 'update', 'close', 'modulos', 'descSingular', 'expedientes'],
  components: {CloseModal},
  data() {
    return {};
  },
  computed: {},
  mounted() {
  },
  validators: {
    'item.desc': (value) => Validator.value(value).required(),
    'item.expediente_id': (value) => Validator.value(value).required(),
    'item.chave': (value) => Validator.value(value).required(),
  },
  methods: {
    handleSubmit() {
      this.$validate().then((success) => {
        if (success) {
          this.update();
        }
      });
    },
  }
};
</script>
