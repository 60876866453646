<template>
  <div>
    <SetoresEdit
      :item="currentItem"
      :update="updateItem"      
      :close="closeEdit"
      :descSingular="descSingular"
      :expedientes="expedientes"
    ></SetoresEdit>
          <Loading :isActive="loading"></Loading>
          <CTabs variant="tabs" class="nav-tabs-boxed">
            <CTab :title=" descPlural ">
              <SetoresTable :items="items" :openEdit="openEdit" :openCreate="openCreate" :deleteConfirm="deleteConfirm" :expedientes="expedientes"/>
            </CTab>
          </CTabs>
  </div>
</template>
<script>
import clone from 'just-clone';
import { get, post, put, del } from '@/helpers/apiRequest';
import SetoresEdit    from './components/SetoresEdit';
import SetoresTable   from './components/SetoresTable';
import _filter      from 'lodash/filter';

const Item = {
  id            : null,
  chave         : null,
  desc          : null,
  expediente_id : null 
}

export default {
  name: 'setores',
  components: {
    SetoresEdit,
    SetoresTable,
  },
  data() {
    return {
      descPlural   : '',
      descSingular : '',
      loading      : false,
      items        : [],
      modulos      : [],
      currentItem  : {},
      currentAccess: false,
      expedientes  : []
    };
  },
  methods: {
    loadDescricaoModulo() {
      const { modulos } = JSON.parse(localStorage.getItem('app'));
      const { descPlural, descSingular } = modulos.find(
        (m) => m.nome === 'ADMIN_SETOR_ATENDIMENTO'
      );
      this.descPlural = descPlural;
      this.descSingular = descSingular;
    },
    loadSetores() {
      this.loading = true;
      get('/admin/setor-atendimento')
        .then((json) => {
          this.items   = json;
          this.loading     = false;
        })
        .catch(() => (this.loading = false));
    },
    loadExpedientes() {
          get(`/admin/expediente`)
          .then((json) => {
            this.expedientes  = json;
            this.loading      = false;
          })
          .catch((err) => {
            this.loading = false;
          });
    },
    openCreate() {
      this.currentItem = clone(Item);
      this.$modal.show('setoresEdit');
    },
    closeCreate() {
      this.$modal.hide('setoresEdit');
    },
    openEdit(item) {
      this.currentItem = clone(item);
      this.loading     = false;
      this.$modal.show('setoresEdit');
    },
    closeEdit() {
      this.$modal.hide('setoresEdit');
    },
    doRequest() {
      return this.currentItem.id ?
      put(`/admin/setor-atendimento/${this.currentItem.id}`, this.currentItem)
      :
      post('/admin/setor-atendimento', this.currentItem);
    },
    updateItem() {
      this.doRequest()
        .then((json) => {
          this.closeEdit();
          this.loadSetores();
          this.$swal({
            title: 'Salvo com sucesso',
            text: json.message,
            showCancelButton: false,
            cancelButtonText: 'Fechar',
            showConfirmButton: true,
            icon: 'success',
          });          
        })
        .catch((error) => {
          this.$swal({
            title: 'Falha ao Salvar',
            text: error.message,
            showCancelButton: true,
            cancelButtonText: 'Fechar',
            showConfirmButton: false,
            icon: 'error',
          });
        });
    },
    deleteConfirm(id) {
      this.$swal({
        title: 'Confirma Remover ?',
        text: 'Essa ação não poderá ser desfeita',
        showCancelButton: true,
        icon: 'warning',
        confirmButtonText: 'Excluir',
        cancelButtonText: 'Cancelar',
      })
        .then((res) => {
          if (res.value) {
            this.loading = true;
            del(`/admin/setor-atendimento/${id}`)
              .then((json) => {
                this.loading = false;
                this.$swal({
                  title: 'Removido com sucesso',
                  icon: 'success',
                });
                this.loadSetores();
              })
              .catch((err) => {
                this.loading = false;
                this.$swal({
                  title: 'Falha ao remover',
                  icon: 'error',
                });
              });
          }
        })
        .catch(() => {});
    },    
  },
  beforeMount() {
    this.loading = true;
    this.loadSetores();
    this.loadDescricaoModulo();
    this.loadExpedientes();
  },
};
</script>
